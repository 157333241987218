<script>
export default {
  name: 'Pobuca',
  page: {
    title: 'Εταίροι − Pobuca'
  }
}
</script>

<template>
  <div>
    <div class="page-header">
      <b-container class="bv-example-row">
          <h1 class="title">Εταίροι − <span class="title-accent">Pobuca</span></h1>
      </b-container>
    </div>
    <div class="page-body">
      <b-container class="bv-example-row">
        <p class="page-text">Η Pobuca SiEBEN ΙΚΕ (www.sieben.gr) παρέχει καινοτόμες λύσεις πληροφορικής σε επιχειρήσεις, κάνοντας χρήση των πλέον σύγχρονων τεχνολογιών. Η συνεχής προσπάθεια για καινοτομία και η εξειδίκευση της σε σύγχρονους τομείς της πληροφορικής, την ανέδειξαν σε μια από τις κορυφαίες εταιρίες στον χώρο της υψηλής τεχνολογίας.
          Διαθέτει μια ευέλικτη ομάδα ανθρώπων με σημαντικές γνώσεις και σπουδές στο αντικείμενο της πληροφορικής τεχνολογίας και των σύγχρονων μεθόδων ανάπτυξης εφαρμογών, αλλά και στο αντικείμενο της οργάνωσης των επιχειρήσεων.
          Έχει αναπτύξει ένα σημαντικό δίκτυο μεταπωλητών σε Ελλάδα και Εξωτερικό και έχει μία συνεργαζόμενη εταιρεία την INEDU A.E. Δραστηριοποιείται στον τομέα του Customer Experience ενώ διαθέτει δικό της τμήμα development σε Αθήνα και Πάτρα.
        </p>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use './../../assets/scss/abstracts' as *;

.page-header {
  background-color: $white;
  padding: $s-xl 0 $s-s 0;
}

.title {
  color: $black;
  font-family: "Waiting for the Sunrise", cursive;
  padding: $s-s 0;
}

.title-accent {
 color: $red;
}

.page-body {
  background-color: $alto;
  color: $black;
  padding: $s-xl 0;
}

.page-text {
  line-height: $s-xl;
}
</style>
